import { useState, useEffect } from "react";
import DataTable from "../../Common/DataTable/DataTable";
import { Modal } from 'react-bootstrap';
import axios from "axios";
import { useSelector } from "react-redux";

const Logs = () => {
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [logs, setLogs] = useState([]);
    const [total, setTotal] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [notification, setNotification] = useState({ show: false, title: "", body: "", button: "" });
    const [bidType, setBidType] = useState("last-bid");
    const [active, setActive] = useState({});
    const [date, setDate] = useState({ from: "", to: "" });
    const admin = useSelector((state) => state.user);


    const headers = ["Lot", "Username", "Auction", "Bid", "Type", "Buyer", "Date", "IP", "Status", ""];
    const showParams = ["lotNumber", "username", "auction", "bid", "eventType", "buyerNumber", "date", "ipAddress", 'status'];

    const handlers = {
        search(keyword) {
            setKeyword(keyword);
        }
    }

    const selects = [
        {
            name: "Bid type",
            id: 2001,
            label: "Select bid type",
            options: [{ label: "All bids", value: 'all-bids', id: 3001 }, { label: "Last bid", value: "last-bid", id: 3002 }],
            action: (value) => {
                setBidType(value);
            }
        }
    ]

    const rowActions = admin.role === "sadmin" ? [
        {
            name: "Won",
            id: 1002,
            action: (car) => {
                const d = { status: "Won" };
                axios.put(`/api/cars/${car.lotNumber}/status`, d)
                    .then(() => {
                        const l = logs.map((c) => {
                            if (c.id === car.id) {
                                return { ...c, status: "Won" }
                            } else {
                                return { ...c }
                            }
                        })
                        setLogs([...l])
                        setNotification({ show: true, title: "Success", body: "Status changed", button: "Ok" })
                    })
                    .catch((err) => {
                        setNotification({ show: true, title: "An error has been detected", body: "Something went wrong", button: "Ok" })
                    })
            }
        },
        {
            name: "Lost",
            id: 1001,
            action: (car) => {
                const d = { status: "Lost" };
                axios.put(`/api/cars/${car.lotNumber}/status`, d)
                    .then(() => {
                        const l = logs.map((c) => {
                            if (c.id === car.id) {
                                return { ...c, status: "Lost" }
                            } else {
                                return { ...c }
                            }
                        })
                        setLogs([...l])
                        setNotification({ show: true, title: "Success", body: "Status changed", button: "Ok" })
                    })
                    .catch((err) => {
                        setNotification({ show: true, title: "An error has been detected", body: "Something went wrong", button: "Ok" })
                    })
            }
        },
        {
            name: "Pending",
            id: 1003,
            action: (car) => {
                const d = { status: "Pending" };
                axios.put(`/api/cars/${car.lotNumber}/status`, d)
                    .then(() => {
                        const l = logs.map((c) => {
                            if (c.id === car.id) {
                                return { ...c, status: "Pending" }
                            } else {
                                return { ...c }
                            }
                        })
                        setLogs([...l])
                        setNotification({ show: true, title: "Success", body: "Status changed", button: "Ok" })
                    })
                    .catch((err) => {
                        setNotification({ show: true, title: "An error has been detected", body: "Something went wrong", button: "Ok" })
                    })
            }
        },
        {
            name: "Payed",
            id: 1004,
            action: (car) => {
                const d = { status: "Payed" };
                axios.put(`/api/cars/${car.lotNumber}/status`, d)
                    .then(() => {
                        const l = logs.map((c) => {
                            if (c.id === car.id) {
                                return { ...c, status: "Payed" }
                            } else {
                                return { ...c }
                            }
                        })
                        setLogs([...l])
                        setNotification({ show: true, title: "Success", body: "Status changed", button: "Ok" })
                    })
                    .catch((err) => {
                        setNotification({ show: true, title: "An error has been detected", body: "Something went wrong", button: "Ok" })
                    })
            }
        }
    ] : [{
        name: "Payed",
        id: 1004,
        action: (car) => {
            const d = { status: "Payed" };
            axios.put(`/api/cars/${car.lotNumber}/status`, d)
                .then(() => {
                    const l = logs.map((c) => {
                        if (c.id === car.id) {
                            return { ...c, status: "Payed" }
                        } else {
                            return { ...c }
                        }
                    })
                    setLogs([...l])
                    setNotification({ show: true, title: "Success", body: "Status changed", button: "Ok" })
                })
                .catch((err) => {
                    setNotification({ show: true, title: "An error has been detected", body: "Something went wrong", button: "Ok" })
                })
        }
    }]

    useEffect(() => {
        axios(`/api/logs/${bidType}?page=${page}&limit=${limit}&keyword=${keyword}&from=${date.from}&to=${date.to}`)
            .then((response) => {
                const data = response.data.data;
                setLogs([...data]);
                setTotal(response.data.total)
            })
            .catch((err) => {
                setNotification({ show: true, title: "An error has been detected", body: "Something went wrong", button: "Ok" })
            })
    }, [limit, page, keyword, bidType, date])

    return (
        <>
            <DataTable
                withoutSearchButton={true}
                headers={headers}
                searchHandler={handlers.search}
                title={"Logs"}
                limit={limit}
                setLimit={setLimit}
                limitControl={true}
                data={logs}
                showParams={showParams}
                pageChanged={page}
                setPageChanged={setPage}
                total={total}
                rowActions={true}
                rowActionsList={rowActions}
                withSelectFilter={true}
                setActiveRow={setActive}
                selects={selects}
                dateRange={true}
                setDate={setDate}
                selectValue={bidType}
            />

            <Modal show={notification.show} onHide={() => setNotification((prev) => ({ ...prev, show: false }))}>
                <Modal.Header><h4>{notification.title}</h4></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
                <Modal.Footer>
                    <button className={"blue-button"} onClick={() => setNotification((prev) => ({
                        ...prev,
                        show: false
                    }))}>{notification.button}</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Logs