import {Link} from "react-router-dom";
import './Header.css'
import axios from "axios";

const Header = ({role}) => {
    const logout = () => {
        axios.post("/api/admin/logout")
            .then(() => {
                window.location.href = "/"
            })
            .catch(() => {
                alert("Something went wrong")
            })
    }

    return(
        <header className={"header-container"}>
            <div className={"header-content"}>
                <Link to={"/"}><img src={"/static/icons/logo.png"} alt={"Header logo"} className={"header-logo"}/></Link>
                <nav className={"navbar-container"}>
                    <ul className={"navbar-list"}>
                        <li><Link to={"/"}>Dashboard</Link></li>
                        <li><Link to={"/users"}>Users</Link></li>
                        <li><Link to={"/users-logs"}>Logs</Link></li>
                        <li><Link to={"/accounts"}>Accounts</Link></li>
                        {role === "sadmin" && <li><Link to={"/admins"}>Admins</Link></li>}
                    </ul>
                </nav>
                <button className={"blue-button"} onClick={logout}>Log out</button>
            </div>
        </header>
    )
}

export default Header